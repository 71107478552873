<template>
  <div>
    <div class="title-path">
      <a href="/">Home</a>
      <small class="fas fa-angle-right mx-1"></small>
      <a href="/clients">Clients</a>
    </div>
    <div class="mb-2 d-block d-lg-none d-md-none">
      <div class="btn btn-sm btn-block form-control btn-primary p-2" v-on:click="newClient" v-if="this.user.role === 'Admin'">
        <i class="fas fa-plus"></i>&nbsp;&nbsp;&nbsp;New Client
      </div>
    </div>
    <div class="row list-actions">
      <div class="col left-column">
        <div class="list-tab" v-on:click="setParamsList('all')" v-bind:class="[clientTab === 'all' ? 'active' : '']">
          All<span>{{clientTotals.totalCount}}</span>
        </div>
        <div class="list-tab" v-on:click="setParamsList('nonErp')" v-bind:class="[clientTab === 'nonErp' ? 'active' : '']">
          From ITS<span>{{clientTotals.totalNonErp}}</span>
        </div>
        <div class="list-tab" v-on:click="setParamsList('isErp')" v-bind:class="[clientTab === 'isErp' ? 'active' : '']">
          From ERP<span>{{clientTotals.totalErp}}</span>
        </div>
      </div>
      <div class="col right-column d-none d-lg-block d-md-block" v-on:click="newClient" v-if="['Admin', 'Manager'].includes(this.user.role)">
        <div class="btn btn-sm btn-primary">
          <i class="fas fa-plus"></i>&nbsp;&nbsp;&nbsp;New Client
        </div>
      </div>
    </div>
    <SearchComponent
      @searchRecord="searchRecord($event)"
      :placeholder="'Search clients by name or code'"
      :showSideOptions="false"
      :isRealTime="true"
    ></SearchComponent>
    <ListComponent
      :records="filteredClients"
      :profile="'clients'"
    ></ListComponent>
  </div>
</template>

<script>
  import SearchComponent from '../Search.vue'
  import ListComponent from '../List.vue'

  export default {
    components: {
      SearchComponent,
      ListComponent
    },
    data() {
      return {
        user: this.$userData.user,
        segments: {
          fnb: "F&B",
          bnhi: "B&HI",
          fmcg: "FMCG",
        },
        projects: [],
        clients: [],
        filteredClients: [],
        clientTab: 'all',
        clientTotals: {
          totalCount: 0,
          totalErp: 0,
          totalNonErp: 0
        }
      }
    },
    methods: {
      loader: function(val){
          this.$emit('loader', val)
      },
      initializeOptions: function(val){
          this.$emit('initializeOptions', val)
      },
      async getProjects(){
        try {
          const response = await this.$http.get(
            `${this.$apiEndpoint}/v1/projects`,
            {
              headers: {
                'Authorization': this.$userData.authToken,
                'Access-Control-Allow-Origin' : '*',
              }
            }
          );
          this.projects = response.data.projects
        } catch (error) {
          console.log(error)
        }
      },
      async getClients(){
        this.loader(true);
        await this.getProjects();
        try {
          const response = await this.$http.get(
            `${this.$apiEndpoint}/v1/clients`,
            {
              headers: {
                'Authorization': this.$userData.authToken,
                'Access-Control-Allow-Origin' : '*',
              }
            }
          );
          this.clients = response.data.clients.map((record) => {
            return {
              ...record,
              projects: this.projects ? this.projects.filter(r => r.client && r.client._id === record._id) : []
            }
          })
          this.filteredClients = this.buildList(this.clients)
          this.clientTotals = {
            totalCount: this.filteredClients.length,
            totalErp: this.filteredClients.filter(r => r.record.isErp).length,
            totalNonErp: this.filteredClients.filter(r => !r.record.isErp).length
          }
          this.loader(false);
        } catch (error) {
          console.log(error)
          this.loader(false);
        }
      },
      searchRecord: function(search){
        this.filteredClients = this.buildList(this.clients.filter(record => (
          (record.name ? record.name.toLowerCase().match(new RegExp(search.toLowerCase())) : false) ||
          (record.code ? record.code.toLowerCase().match(new RegExp(search.toLowerCase())) : false)
        )))
      },
      validateLogo: function (logo){
        return logo ? logo : 'https://tools.iripple.com/uploads/-/system/appearance/logo/1/iRipple_logo.png'
      },
      setParamsList: function(val){
        this.clientTab = val;
        switch(val){
          case 'isErp':
            this.filteredClients = this.buildList(this.clients.filter(r => r.isErp))
            break
          case 'nonErp':
            this.filteredClients = this.buildList(this.clients.filter(r => !r.isErp))
            break
          default:
            this.filteredClients = this.buildList(this.clients)
        }
      },
      buildList: function(records){
        return records.map((record) => {
          return {
            key: record.code,
            avatar: this.validateLogo(record.logo),
            header: record.code ? `[${record.code}] ${record.name}` : record.name,
            subheader: record.projects.length > 0 ? record.projects.map(r => r.bunit.name).join(" | ") : "No business units",
            indicators: [
              {
                key: `${record._id}_1`,
                text: this.segments[record.segment] || "No segment",
                background: "escalated"
              },
              {
                key: `${record._id}_2`,
                text: record.phase == 1 ? "Phase 1" : "Phase 0",
                background: record.phase == 1 ? "warning" : "resolved"
              },
              {
                key: `${record._id}_3`,
                text: record.isActive ? "Active" : "Inactive",
                background: record.isActive ? "primary-default" : "primary-dark"
              }
            ],
            timestamp: {
              updatedAt: record.updatedAt
            },
            record: record
          }
        })
      },
      validateUser: async function(isValid){
        if(!isValid){
          this.$alertify({
            group: 'notification',
            title: `Access denied`,
            type: 'warning',
            text: `You don't have access for this module.`
          })
          await this.$sleep(500);
          this.$router.push('/')
        }
      },
      newClient: function(){
        this.$router.push('/clients/new')
      }
    },
    mounted: function() {
      document.title = `Clients | iRipple Helpdesk`;
      this.getClients();
      this.validateUser(this.user.role !== "Customer")
    }
  }
</script>
